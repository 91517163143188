// Vue
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'
import VuexPersistence from 'vuex-persist'

// Modules
import * as modules from './modules'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
  modules,
  plugins: [
    pathify.plugin,
    vuexLocal.plugin
  ]
})

export default store

export const ROOT_DISPATCH = Object.freeze({ root: true })
